import { Auth } from '@aws-amplify/auth';
import { SegmentMarker } from './../../../_models/demographics';
import { NubbleService } from './../../../_services/nubble.service';
import { QuestionService } from '../../../_services/question.service';
import { AuthService } from '../../../_services/auth.service';
import { Marker } from './../../../_models/marker';
import { element } from 'protractor';
import { map } from 'rxjs/operators';
import { Demographics, Quota, Target } from '../../../_models/demographics';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Question } from '../../../_models/question';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxDropdownConfig } from 'ngx-select-dropdown';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DemographicsComponent),
    },
  ],
})

// NgModel ControlValueAccessor
//https://www.youtube.com/watch?v=lNwsnuWSD7o
export class DemographicsComponent implements OnInit, ControlValueAccessor {
  @Input() isEngagementPage: boolean = false;

  // questions
  // object
  // ngmodel

  @Input() fullLaunchQuota: number = 200;
  model: Demographics = {
    targets: [],
    quotas: [],
    enablePrescreenerQuotas: false,
    zips: [],
    division: [],
    region: [],
    markers: [],
    ageTarget: {
      target: {
        min: 0,
        max: 0,
      },
      buckets: [],
    },
  };

  bucketReady: boolean = false;
  questionsForDD: any[] = [];
  selectedDemographics: any[] = []; //For dropdown
  selectedTargets: Question[] = [];
  selectedQuotas: Question[] = [];

  questions: Question[] = [];
  quotas: any[] = [];
  createBucketName: string = '';
  markerList: Marker[] = [];
  selectedMarkers: Marker[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'questionKey',
    textField: 'questionKey',
    allowSearchFilter: true,
  };

  markerconfig = {
    displayKey: 'name',
    search: false,
    height: '350px',
    limitTo: 0,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name',
    selectAllLabel: 'Select all',
    enableSelectAll: true,
  };

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(
    private questionService: QuestionService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private nubble: NubbleService,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    // only get markers for qrious
    // obsolete
    if (this.auth.user.userDetails.dash != true) {
      this.getMarkers();
    }
    this.getQuestions();
  }

  writeValue(obj: any) {
    this.model = obj;

    if (this.questions.length > 0 && this.model.quotas.length > 0) {
      // wait for questions to be loded
      this.selectedDemographics = this.model.targets.map(
        (item) => item.questionKey,
      );
      this.targetChanged();
      this.reloadQuestions();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleModal(content: any, target: any) {
    if (this.model.ageTarget == null) {
      this.model.ageTarget = {
        target: {
          min: 0,
          max: 0,
        },
        buckets: [],
      };
    }

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  // get questions and questions for dropdown
  getQuestions() {
    this.questionService.allQuestions().subscribe({
      next: (data) => {
        this.questions.push(...data);
        this.questionsForDD = this.questions
          .filter((x) => x.questionKey !== 'DOB')
          .map((item) => item.questionKey);
      },
      error: (error) => {
        this.toastr.error('Unable to fetch questions');
      },
      complete: () => {
        if (this.questionsForDD.filter((x) => x === 'AGE').length < 1) {
          this.questions.push({
            questionId: 12,
            questionKey: 'AGE',
            questionType: '',
            questionOptions: [],
            questionText: '',
            language: '',
            country: '',
            category: [],
          });
          this.questionsForDD.push('AGE');
        }
        if (this.model.quotas.length > 0) {
          this.writeValue(this.model);
        }
      },
    });
  }

  markersChanged() {
    this.model.markers = [];
    this.selectedMarkers.forEach((element) => {
      this.model.markers.push({ id: element.id, name: element.name });
    });
  }

  onDemograpicAdd(event: any) {
    // Add question to targets
    const newTarget: Target = {
      questionKey: event,
      ids: [],
    };

    this.model.targets.push(newTarget);
    this.reloadQuestions();
  }

  onDemograpicRemove(event) {
    switch (event.toUpperCase()) {
      case 'AGE':
        this.model.ageTarget = {
          target: {
            min: 0,
            max: 0,
          },
          buckets: [],
        };
        break;
      case 'ZIP':
        this.model.zips = [];
        break;
      default:
        break;
    }

    this.model.targets = this.model.targets.filter(
      (item) => item.questionKey != event,
    );

    this.selectedQuotas = this.selectedQuotas.filter(
      (x) => x.questionKey != event,
    );

    this.reloadQuestions();
  }

  async reloadQuestions() {
    // filter questions to selected
    this.selectedTargets = this.questions.filter((item) =>
      this.selectedDemographics.includes(item.questionKey),
    );

    // get selected options
    this.selectedTargets.forEach((element) => {
      var target = this.model.targets.find(
        (item) => item.questionKey == element.questionKey,
      );
      var ids = target?.ids ?? []; // get selected ids from target
      element.selectedOptions = element.questionOptions.filter((item) =>
        ids.includes(item.id),
      ); // filter options of selected
    });

    this.loadQuotas();
  }

  dropdownConfig(target: any) {
    var config: NgxDropdownConfig = {
      displayKey: 'optionText',
      search: target.questionOptions.length > 10,
      height: '350px',
      placeholder: target.questionKey,
      limitTo: 0,
      moreText: 'more',
      noResultsFound: 'No results found!',
      searchPlaceholder: 'Search',
      searchOnKey: 'optionText',
      clearOnSelection: false,
      inputDirection: 'ltr',
      selectAllLabel: 'Select all',
      enableSelectAll: true,
    };

    return config;
  }

  selectedTargetsDropdownConfig(selectedQuestions: any) {
    var config: NgxDropdownConfig = {
      displayKey: 'questionKey',
      height: '350px',
      placeholder: selectedQuestions.questionKey,
      limitTo: 0,
      moreText: 'more',
      noResultsFound: 'No results found!',
      searchPlaceholder: 'Search',
      searchOnKey: 'questionKey',
      clearOnSelection: false,
      inputDirection: 'ltr',
      selectAllLabel: 'Select All',
      enableSelectAll: true,
    };
    return config;
  }

  removeAgeBucket(index: number): void {
    this.model.ageTarget.buckets.splice(index, 1);
    index;
  }

  addAgeBucket() {
    this.model.ageTarget.buckets.push({
      min: 0,
      max: 0,
    });
  }

  uploadDemographics(fileInput: any, demographicProperty: string) {
    let file = fileInput.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      let csv: string = reader.result as string;
      csv = csv.replace(/[\n\r]+/g, '-');
      switch (demographicProperty.toUpperCase()) {
        case 'ZIPS':
          this.model.zips = csv.split('-');
          this.model.zips = this.model.zips.filter((item) => item.length > 1);
          break;
      }
    };
  }

  targetChanged() {
    this.selectedQuotas = this.questions.filter((item) =>
      this.model.quotas.some((x) => x.questionKey === item.questionKey),
    );

    this.selectedTargets.forEach((question) => {
      var targetToUpdate = this.model.targets.find(
        (item) => item.questionKey === question.questionKey,
      );

      if (targetToUpdate != null) {
        targetToUpdate.ids = question.selectedOptions!.map((item) => item.id);
        this.model.targets = this.model.targets.filter(
          (item) => item.questionKey !== question.questionKey,
        );
        this.model.targets.push(targetToUpdate);
      }
    });
    this.loadQuotas();
  }

  selectedQuotasChanged(event) {
    // This will populate the dropdown when a target is selected in the quota section
    // If no results then return an empty array
    this.selectedQuotas = event.value.length
      ? this.questions.filter((item) =>
          event.value.some((x) => x.questionKey === item.questionKey),
        )
      : [];
    this.loadQuotas();
  }

  removeSelectedQuotas(quotaToRemove: Quota): void {
    const quotaIndex = this.selectedTargets.findIndex(
      (x) => x.questionKey === quotaToRemove.questionKey,
    );

    if (quotaIndex === -1) return; // Exit early if not found

    const quota = this.selectedTargets[quotaIndex];

    // Ensure selectedOptions is not undefined before filtering
    quota.selectedOptions = (quota.selectedOptions ?? []).filter(
      (option) => option.id !== quotaToRemove.optionIds[0],
    );

    // Replace the updated quota in the array
    this.selectedTargets.splice(quotaIndex, 1, quota);

    this.targetChanged();
  }

  numberChange(item) {
    item.percent = ((item.number / this.fullLaunchQuota) * 100).toFixed(2);
  }

  percentChange(item) {
    item.number = Math.ceil((item.percent / 100) * this.fullLaunchQuota);
  }

  async loadQuotas() {
    let oldQuotas = this.model.quotas;

    let buckets = this.model.quotas.filter((item) => item.optionIds.length > 1);
    const currentTargets = JSON.parse(JSON.stringify(this.model.targets)); // copy array without reference
    let targets: any[] = [];

    if (buckets.length > 0) {
      currentTargets.forEach((target) => {
        let newTarget = target;
        let ids: string[] = [];
        target.ids.forEach((tid) => {
          let targetId = +tid; // change to int
          let alreadyInUse = false;

          buckets.forEach((bucket) => {
            if (target.questionKey === bucket.questionKey) {
              bucket.optionIds.forEach((id) => {
                if (targetId === id) {
                  alreadyInUse = true;
                }
              });
            }
          });

          if (alreadyInUse === false) {
            ids.push(tid);
          }
        });
        newTarget.ids = ids;
        targets.push(newTarget);
        //if not in bucket add
      });
    } else {
      targets = currentTargets;
    }

    // find buckets for
    // only add targets not in Buckets
    // add buckets
    this.model.quotas = [];

    targets.forEach((target) => {
      var question = this.questions.find(
        (q) => q.questionKey == target.questionKey,
      );

      // Add quotas in the model if it exists on the selectedQuotas dropdown
      var isTargetSelectedForQuotas = this.selectedQuotas.find(
        (x) => x.questionKey == target?.questionKey,
      );

      if (
        isTargetSelectedForQuotas === null ||
        isTargetSelectedForQuotas === undefined
      ) {
        return;
      }

      if (target.questionKey === 'AGE') {
        this.model.ageTarget.buckets.forEach((bucket) => {
          this.model.quotas.push({
            questionKey: 'AGE',
            optionIds: [],
            optionText: bucket.min + '-' + bucket.max,
            hardStop: true,
            number: 0,
            percent: 0,
            min: bucket.min,
            max: bucket.max,
          });
        });
      } else {
        target.ids.forEach((id) => {
          var option = question?.questionOptions.find((q) => q.id == +id);
          let options = [+id];

          this.model.quotas.push({
            questionKey: target.questionKey,
            optionIds: options,
            optionText: option?.optionText || '*Notify admin*',
            hardStop: true,
            number: 0,
            percent: 0,
            min: 0,
            max: 0,
          });
        });
      }
    });

    //add buckets back in
    buckets.forEach((bucket) => {
      this.model.quotas.push({
        questionKey: bucket.questionKey,
        optionIds: bucket.optionIds,
        optionText: bucket.optionText,
        hardStop: true,
        number: 0,
        percent: 0,
        min: 0,
        max: 0,
      });
    });

    this.model.quotas.forEach((item) => {
      let oldQuota = oldQuotas.find(
        (q) =>
          q.questionKey == item.questionKey &&
          q.optionText.toLowerCase() == item.optionText.toLocaleLowerCase(),
      );

      if (oldQuota != null) {
        item.number = oldQuota.number;
        item.percent = parseFloat(
          ((oldQuota.number / this.fullLaunchQuota) * 100).toFixed(2),
        );
        item.hardStop = oldQuota.hardStop;
      }
    });
  }

  saveAgeTargets() {
    this.loadQuotas();
  }

  checkChange(event) {
    var checked = this.model.quotas.filter((item) => item.checked === true);
    if (checked.length > 0) {
      this.disableQuotas();
    } else {
      this.enableQuotas();
    }

    if (checked.length > 1) {
      this.bucketReady = true;
    } else {
      this.bucketReady = false;
    }

    // if none checked enable all
    // enable all in same demographic
  }

  disableQuotas() {
    // find one thats checked
    //get demog
    var checked = this.model.quotas.find((q) => q.checked === true);
    if (checked) {
      this.model.quotas.forEach((item) => {
        if (item.questionKey === checked?.questionKey) {
          item.disable = false;
        } else {
          item.disable = true;
        }
      });
    }
  }

  enableQuotas() {
    this.model.quotas.forEach((item) => {
      item.disable = false;
    });
  }

  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  createBucket() {
    // hide Modal
    // Remove seletected items
    let checkedQuotas = this.model.quotas.filter(
      (item) => item.checked === true,
    );
    this.model.quotas = this.model.quotas.filter(
      (item) => item.checked !== true,
    );
    let ids: number[] = [];

    checkedQuotas.forEach((item) => {
      item.optionIds.forEach((id) => {
        ids.push(id);
      });
    });

    this.model.quotas.push({
      questionKey: checkedQuotas[0].questionKey,
      optionIds: ids,
      optionText: this.createBucketName,
      hardStop: true,
      number: 0,
      percent: 0,
      min: 0,
      max: 0,
    });

    // create bucket with selected items and name
    this.bucketReady = false;
    this.enableQuotas();
    // clear name from modal
    this.createBucketName = '';
  }

  createBucketModal(e: any, bucket: any) {
    e.preventDefault();
    this.modalService
      .open(bucket, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  explodeBucket(bucket: Quota) {
    // check target exists
    let target = this.model.targets.find(
      (item) => item.questionKey == bucket.questionKey,
    );
    this.model.quotas = this.model.quotas.filter(
      (item) =>
        !(
          item.questionKey == bucket.questionKey &&
          this.arrayEquals(item.optionIds, bucket.optionIds)
        ),
    );

    if (!target) {
      return;
    }

    var question = this.questions.find(
      (q) => q.questionKey == bucket.questionKey,
    );
    bucket.optionIds.forEach((id) => {
      var option = question?.questionOptions.find((q) => q.id == +id);

      if (target?.ids.includes(id)) {
        this.model.quotas.push({
          questionKey: bucket.questionKey,
          optionIds: [id],
          optionText: option?.optionText || '',
          hardStop: true,
          number: 0,
          percent: 0,
          min: bucket.min,
          max: bucket.max,
        });
      }
    });
    // target removed ?? delete
    // create quota for each optionIds
  }

  getMarkers() {
    this.nubble.listMarkers().subscribe(
      (data) => {
        this.markerList = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch markers');
      },
      () => {
        if (!this.markerList) {
          this.markerList = [];
        }
        if (!this.model?.markers) {
          this.model.markers = [];
        }
        this.selectedMarkers = this.markerList?.filter(
          (marker) =>
            this.model?.markers.filter((x) => x.id === marker.id).length > 0,
        );
        this.writeValue(this.model);
      },
    );
  }
}
