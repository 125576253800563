
<div class="modal-header">
  <h4 class="modal-title">Upload final IDs</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">

  <div class="mb-3" *ngIf="results.length < 1">
    <label for="formFile" class="form-label">{{ f.spids?.value.length }} Id's Uploaded</label>
    <input #fileInput (input)="uploadSpids($event)" accept=".csv" class="form-control" type="file" id="formFile">
  </div>

  <div class="row" *ngIf="results.length > 0">
    <div class="col table-responsive">
      <table class="table table-striped table-md ">
        <thead>
          <tr>
            <th scope="col">JobName</th>
            <th scope="col">Original Completes</th>
            <th scope="col">Valid Completes</th>
            <th scope="col">Rejected</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let result of results">
            <td>{{ result.jobName }}</td>
            <td>{{ result.originalCompletes }}</td>
            <td>{{ result.validCompletes }}</td>
            <td>{{ result.rejected }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button [disabled]="loading" class="btn btn-primary" (click)="save()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{ results.length > 0 ? "Continue" : "Upload"}}
  </button>

  <button [disabled]="loading" type="button" class="btn btn-secondary" (click)="decline()">Close</button>
</div>