import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { ParticipantValidationSummary } from '../_models/participants-validation-result';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  constructor(
    private http: HttpClient
  ) { }

  validateSpids(projectId: string, spids: string[]) {
    return this.http.post<ParticipantValidationSummary[]>(`${environment.apiUrl}/api/participant/validate-final-completes?projectId=${projectId}`, spids);
  }

  updateSpids(projectId: string, spids: string[]) {
    return this.http.post<ParticipantValidationSummary[]>(`${environment.apiUrl}/api/participant/finalize-completes?projectId=${projectId}`, spids);
  }
}
