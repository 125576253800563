<div class="row mt-3" *ngIf="auth.user?.userDetails?.dash != true">
  <div class="col">
    <div class="card">
      <div class="card-header">Markers</div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <input type="hidden" />
              <ngx-select-dropdown
                [config]="markerconfig"
                [multiple]="true"
                [(ngModel)]="selectedMarkers"
                [options]="markerList"
                (change)="markersChanged()">
              </ngx-select-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <div class="card">
      <div class="card-header">Demographics</div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <input type="hidden" />
              <ng-multiselect-dropdown
                [settings]="dropdownSettings"
                [placeholder]="'Choose...'"
                [data]="questionsForDD"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedDemographics"
                (onSelect)="onDemograpicAdd($event)"
                (onDeSelect)="onDemograpicRemove($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Targeting -->
<div class="row mt-3">
  <div class="col">
    <div class="card">
      <div class="card-header">Targeting</div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <span *ngIf="model.targets.length === 0"
              >No demographics selected</span
            >
            <ng-container *ngFor="let question of selectedTargets">
              <div *ngIf="question.questionKey == 'AGE'" class="col-4 mt-3">
                <a
                  class="form-control btn btn-outline-secondary"
                  (click)="toggleModal(content, question)">
                  AGE
                  <span
                    *ngIf="
                      model.ageTarget.target.min > 0 &&
                      model.ageTarget.target.max > 0
                    "
                    >({{ model.ageTarget.target.min }}-{{
                      model.ageTarget.target.max
                    }})
                  </span></a
                >
              </div>
              <div
                *ngIf="question.questionKey.toUpperCase() === 'ZIP'"
                class="col-4 mt-3">
                <label class="form-control btn btn-outline-secondary">
                  <span *ngIf="model.zips.length === 0">Zip (.csv only)</span>
                  <span *ngIf="model.zips.length > 0">
                    {{ model.zips.length }} Zips targeted</span
                  >
                  <input
                    type="file"
                    (change)="uploadDemographics($event, 'zips')"
                    style="display: none" />
                </label>
              </div>

              <div
                *ngIf="
                  question.questionKey != 'AGE' && question.questionKey != 'ZIP'
                "
                class="col-4 mt-3">
                <ngx-select-dropdown
                  [optionItemTemplate]="optionTemplate"
                  [selectedItemTemplate]="optionTemplate"
                  tabindex="0"
                  [config]="dropdownConfig(question)"
                  [multiple]="true"
                  [(ngModel)]="question.selectedOptions"
                  [options]="question.questionOptions"
                  (change)="targetChanged()">
                </ngx-select-dropdown>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--{{model|json}}  -->

<div
  class="row mt-3"
  *ngIf="auth.user?.userDetails?.bb != true && !isEngagementPage">
  <div class="col">
    <div class="card">
      <div class="card-header">
        Quotas
        <div class="form-check form-check-inline form-switch float-end">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="model.enablePrescreenerQuotas" />
          <label class="form-check-label">Enable Prescreener</label>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row" *ngIf="model.enablePrescreenerQuotas">
            <div class="row align-items-center">
              <div class="col-auto">
                <label for="inputPassword6" class="col-form-label"
                  >Select targeting attributes you would like to create quotas
                  on:
                </label>
              </div>
              <div class="col-md-4">
                <ngx-select-dropdown
                  [optionItemTemplate]="selectedQuestionsOptionTemplate"
                  [selectedItemTemplate]="selectedQuestionsOptionTemplate"
                  tabindex="0"
                  [config]="selectedTargetsDropdownConfig(selectedTargets)"
                  [multiple]="true"
                  [options]="selectedTargets"
                  [(ngModel)]="selectedQuotas"
                  (change)="selectedQuotasChanged($event)">
                </ngx-select-dropdown>
              </div>
            </div>

            <span
              *ngIf="
                model.targets.length > 0 && !model.enablePrescreenerQuotas
              ">
              Prescreener Disabled
            </span>

            <span
              *ngIf="
                model.quotas.length < 1 &&
                model.targets.length > 0 &&
                model.enablePrescreenerQuotas
              "
              >No targets selected
            </span>

            <div
              *ngIf="model.quotas.length > 0 && model.enablePrescreenerQuotas"
              class="table-responsive">
              <div class="row g-3 align-items-center">
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-outline-success form-control"
                    [disabled]="!bucketReady"
                    (click)="createBucketModal($event, bucket)">
                    Create Bucket
                  </button>
                </div>
              </div>

              <table class="table table-striped table-hover">
                <thead class="table">
                  <tr>
                    <th></th>
                    <th>Demographic</th>
                    <th>Name</th>
                    <th>Hard Stop</th>
                    <th>N / {{ fullLaunchQuota }}</th>
                    <th>%</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let quota of model.quotas">
                    <tr>
                      <td>
                        <input
                          *ngIf="
                            quota.questionKey !== 'AGE' &&
                            quota.optionIds.length < 2
                          "
                          class="form-check-input"
                          type="checkbox"
                          [disabled]="quota.disable === true"
                          [checked]=""
                          (change)="checkChange($event)"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="quota.checked"
                          aria-label="..." />
                        <button
                          *ngIf="
                            quota.questionKey !== 'AGE' &&
                            quota.optionIds.length > 1
                          "
                          (click)="explodeBucket(quota)"
                          title="Explode Bucket"
                          type="button"
                          class="btn-close"
                          aria-label="Close"></button>
                      </td>
                      <td>
                        <label>{{ quota.questionKey }}</label>
                      </td>
                      <td>
                        <label>{{ quota.optionText }}</label>
                      </td>
                      <td>
                        <div
                          class="form-check form-check-inline form-switch align-items-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="quota.hardStop" />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="quota.number"
                          (focusout)="numberChange(quota)"
                          (keydown.enter)="numberChange(quota)"
                          class="form-control" />
                      </td>
                      <td>
                        <input
                          type="number"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="quota.percent"
                          (focusout)="percentChange(quota)"
                          (keydown.enter)="percentChange(quota)"
                          class="form-control" />
                      </td>
                      <td>
                        <button
                          (click)="removeSelectedQuotas(quota)"
                          type="button"
                          class="btn btn-outline-secondary form-control bi bi-trash"></button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Age</h4>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <label class="" for="termUrl">Min Age</label>
        <input
          type="number"
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="model.ageTarget.target.min" />
      </div>
      <div class="col">
        <label class="" for="termUrl">Max Age</label>
        <input
          type="number"
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="model.ageTarget.target.max" />
      </div>
    </div>
    <label class="lead mt-5" for="termUrl">Quota Buckets</label>
    <div class="card">
      <ng-container
        *ngFor="let bucket of model.ageTarget.buckets; let i = index">
        <div class="row m-3">
          <div class="col">
            <label class="" for="termUrl">Min Age</label>
            <input
              type="number"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="bucket.min" />
          </div>
          <div class="col">
            <label class="" for="termUrl">Max Age</label>
            <input
              type="number"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="bucket.max" />
          </div>
          <div class="col-2">
            <label class="lead" for="vendorFullLaunchQuota"
              ><span class="invisible">hi</span></label
            >
            <button
              (click)="removeAgeBucket(i)"
              type="button"
              class="btn btn-outline-danger form-control bi bi-trash"></button>
          </div>
        </div>
      </ng-container>
      <div class="row m-3">
        <div class="col-4">
          <a class="btn btn-success mt-1" (click)="addAgeBucket()"
            >Add Bucket</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group mt-3">
      <a
        (click)="saveAgeTargets(); modal.dismiss('Cancel click')"
        class="btn btn-primary">
        Save
      </a>
      <a (click)="modal.dismiss('Cancel click')" class="btn btn-link">Cancel</a>
    </div>
  </div>
</ng-template>

<ng-template #bucket let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Create Bucket</h4>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <label class="" for="termUrl">Name</label>
        <input
          type="text"
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="createBucketName" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group mt-3">
      <a
        (click)="createBucket(); modal.dismiss('Cancel click')"
        class="btn btn-primary">
        Save
      </a>
      <a (click)="modal.dismiss('Cancel click')" class="btn btn-link">Cancel</a>
    </div>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item" let-config="config">
  {{ item.optionText }}
</ng-template>

<ng-template
  #selectedQuestionsOptionTemplate
  let-item="item"
  let-config="config">
  {{ item.questionKey }}
</ng-template>
