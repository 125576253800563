import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrService {
  constructor(private toastr: ToastrService) {}

  showSuccess(message: string, title?: string) {
    this.toastr.success(message, title);
    this.storeMessage('success', message, title);
  }

  showError(message: string, title?: string) {
    this.toastr.error(message, title);
    this.storeMessage('error', message, title);
  }

  showWarning(message: string, title?: string) {
    this.toastr.warning(message, title);
    this.storeMessage('warning', message, title);
  }

  showInfo(message: string, title?: string) {
    this.toastr.info(message, title);
    this.storeMessage('info', message, title);
  }

  private storeMessage(type: string, message: string, title?: string) {
    const messages = JSON.parse(localStorage.getItem('toastrMessages') || '[]');
    messages.push({ type, message, title });
    localStorage.setItem('toastrMessages', JSON.stringify(messages));
  }

  displayStoredMessages() {
    const messages = JSON.parse(localStorage.getItem('toastrMessages') || '[]');
    messages.forEach(({ type, message, title }: any) => {
      this.toastr[type](message, title);
    });
    localStorage.removeItem('toastrMessages'); // Clear after displaying
  }
}