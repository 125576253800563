<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item"><a routerLink="/instances">Instances</a></li>
    <li *ngIf="!isAddMode" class="breadcrumb-item active" aria-current="page">
      Edit Instance
    </li>
    <li *ngIf="isAddMode" class="breadcrumb-item active" aria-current="page">
      Add Instance
    </li>
  </ol>
</nav>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card">
    <div class="card-body">
      <div class="card">
        <div class="card-header">General</div>
        <div class="card-body">
          <input type="hidden" formControlName="id" class="form-control" />
          <input type="hidden" formControlName="secret" class="form-control" />
          <div class="row">
            <div class="form-group col-6">
              <label for="name">Name</label>
              <input
                type="text"
                formControlName="name"
                placeholder="Qrious Insight"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
              </div>
            </div>

            <div class="form-group col-6">
              <label for="lastName">Domain</label>
              <input
                type="text"
                formControlName="url"
                placeholder="qriousinsight.com"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.url.errors }" />
              <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                <div *ngIf="f.url.errors.required">Domain is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-2">
        <div class="card-header">Survey's</div>
        <div class="card-body">
          <div class="form-row">
            <div class="row">
              <div class="form-group col-4">
                <label for="lastName">Project Prefix</label>
                <input
                  type="text"
                  formControlName="projectPrefix"
                  placeholder="QI-"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.projectPrefix.errors
                  }" />
                <div
                  *ngIf="submitted && f.projectPrefix.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.projectPrefix.errors.required">
                    Project Prefix is required
                  </div>
                </div>
              </div>
              <div class="form-group col-2">
                <label for="forensicThreshold">Forensic Threshold</label>
                <input
                  type="number"
                  formControlName="forensicThreshold"
                  class="form-control"
                  placeholder="70"
                  [ngClass]="{
                    'is-invalid': submitted && f.forensicThreshold.errors
                  }" />
                <div
                  *ngIf="submitted && f.forensicThreshold.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.forensicThreshold.errors.required">
                    Threshold is required
                  </div>
                </div>
              </div>
              <div class="form-group col-2">
                <label for="fraudThreshold">RelevantId Threshold</label>
                <input
                  type="number"
                  formControlName="fraudThreshold"
                  class="form-control"
                  placeholder="70"
                  [ngClass]="{
                    'is-invalid': submitted && f.fraudThreshold.errors
                  }" />
                <div
                  *ngIf="submitted && f.fraudThreshold.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.fraudThreshold.errors.required">
                    Threshold is required
                  </div>
                </div>
              </div>
              <div class="form-group col-2">
                <label for="fraudThreshold">R. Defender Threshold</label>
                <input
                  type="number"
                  formControlName="researchDefenderThreshold"
                  placeholder="68"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.researchDefenderThreshold.errors
                  }" />
                <div
                  *ngIf="submitted && f.researchDefenderThreshold.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.researchDefenderThreshold.errors.required">
                    Threshold is required
                  </div>
                </div>
              </div>
              <div class="form-group col-2">
                <label for="fraudActivityThreshold">
                  RD. Activity Threshold
                </label>
                <input
                  type="number"
                  formControlName="researchDefenderActivityThreshold"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.researchDefenderActivityThreshold.errors
                  }" />
                <div
                  *ngIf="
                    submitted && f.researchDefenderActivityThreshold.errors
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="f.researchDefenderActivityThreshold.errors.required">
                    Threshold is required
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col">
              <label for="forensicAPIKey">Forensic API Key</label>
              <input
                type="text"
                formControlName="forensicAPIKey"
                placeholder="00000000-0000-0000-0000-000000000000"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && f.forensicAPIKey.errors
                }" />
              <div
                *ngIf="submitted && f.forensicAPIKey.errors"
                class="invalid-feedback">
                <div *ngIf="f.forensicAPIKey.errors.required">
                  Forensic API Key is required
                </div>
              </div>
            </div>
            <div class="form-group col">
              <label for="relevantIdAPIKey">RelevantId API Key</label>
              <input
                type="text"
                formControlName="relevantIdAPIKey"
                placeholder="00000000-0000-0000-0000-000000000000"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && f.relevantIdAPIKey.errors
                }" />
              <div
                *ngIf="submitted && f.relevantIdAPIKey.errors"
                class="invalid-feedback">
                <div *ngIf="f.relevantIdAPIKey.errors.required">
                  RelevantId API Key is required
                </div>
              </div>
            </div>
            <div class="form-group col">
              <label for="relevantIdAPIKey">Research Defender API Key</label>
              <input
                type="text"
                formControlName="researchDefenderAPIKey"
                class="form-control"
                placeholder="00000000-0000-0000-0000-000000000000"
                [ngClass]="{
                  'is-invalid': submitted && f.researchDefenderAPIKey.errors
                }" />
              <div
                *ngIf="submitted && f.researchDefenderAPIKey.errors"
                class="invalid-feedback">
                <div *ngIf="f.researchDefenderAPIKey.errors.required">
                  Research Defender API Key is required
                </div>
              </div>
            </div>
            <div class="form-group col">
              <label for="smartyStreetsKey">Smarty Streets Key</label>
              <input
                type="text"
                placeholder="00000000-0000-0000-0000-000000000000"
                formControlName="smartyStreetsKey"
                class="form-control" />
            </div>
            <div class="form-group col">
              <label for="smartyStreetsToken">Smarty Streets Token</label>
              <input
                type="text"
                placeholder="00000000-0000-0000-0000-000000000000"
                formControlName="smartyStreetsToken"
                class="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div
        class="card mt-2"
        *ngIf="authService.user?.userDetails?.dash != true">
        <div class="card-header">ValueMe</div>
        <div class="card-body">
          <!-- Config Section-->
          <div class="container">
            <span class="lead">Config</span>
            <hr />
            <div class="row">
              <div class="form-group col-3">
                <div class="col">
                  <label class="lead" for="country">Country</label>
                  <select
                    required
                    formControlName="country"
                    class="form-select"
                    required
                    [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                    <option value="">-- Choose Country --</option>
                    <option value="US">United States</option>
                    <option value="ID">Indonesia</option>
                    <option value="CA">Canada</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Åland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia, Plurinational State of</option>
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>

                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Côte d'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curaçao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">
                      Heard Island and McDonald Islands
                    </option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>

                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">
                      Macedonia, the former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Réunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthélemy</option>
                    <option value="SH">
                      Saint Helena, Ascension and Tristan da Cunha
                    </option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin (French part)</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten (Dutch part)</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>

                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">
                      Venezuela, Bolivarian Republic of
                    </option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                  <div
                    *ngIf="submitted && f.country.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.country.errors?.required">
                      Country is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group col-3">
                <div class="col">
                  <label class="lead" for="platform">Platform</label>
                  <select
                    required
                    formControlName="platform"
                    class="form-select"
                    required
                    [ngClass]="{
                      'is-invalid': submitted && f.platform.errors
                    }">
                    <option value="">-- Choose Platform --</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                    <option value="Web">Web</option>
                  </select>
                  <div
                    *ngIf="submitted && f.platform.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.platform.errors?.required">
                      Platform is required
                    </div>
                  </div>
                </div>
              </div>

              <!--realityMineAPIKey -->
              <div class="form-group col-3">
                <label class="lead" for="realityMineAPIKey"
                  >Reality Mine API Key</label
                >
                <input
                  type="text"
                  formControlName="realityMineAPIKey"
                  placeholder="00000000-0000-0000-0000-000000000000"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.realityMineAPIKey.errors
                  }" />
                <div
                  *ngIf="submitted && f.realityMineAPIKey.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.realityMineAPIKey.errors.required">
                    API Key is required
                  </div>
                </div>
              </div>

              <!--realityMineGroup -->
              <div class="form-group col-3">
                <label class="lead" for="realityMineGroup"
                  >Reality Mine Group</label
                >
                <input
                  type="text"
                  formControlName="realityMineGroup"
                  class="form-control"
                  placeholder="Lighthouse.TestGroup"
                  [ngClass]="{
                    'is-invalid': submitted && f.realityMineGroup.errors
                  }" />
                <div
                  *ngIf="submitted && f.realityMineGroup.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.realityMineGroup.errors.required">
                    Group is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Recruitment -->
          <div class="container mt-5">
            <span class="lead">Recruitment</span>
            <hr />
            <div class="row">
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="allowEntryWithoutReferralCode"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Allow Entry Without Referral Code</label
                    >
                  </div>
                </div>
              </div>

              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="isBackUpPanel"
                      type="checkbox" />
                    <label class="form-check-label">Is BackUpPanel</label>
                  </div>
                </div>
              </div>

              <!--xxxxxxx -->
            </div>
          </div>

          <!--Branded -->
          <div class="container mt-5">
            <span class="lead">Branded</span>
            <hr />
            <div class="row">
              <div class="form-group col-2">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="isBrandedPanel"
                      type="checkbox" />
                    <label class="form-check-label">Is Branded Panel</label>
                  </div>
                </div>
              </div>

              <!--realityMineAPIKey -->
              <div class="form-group col-4">
                <label class="lead" for="brandName ">Brand Name </label>
                <input
                  type="text"
                  formControlName="brandName"
                  placeholder="Bump Club"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.brandName.errors
                  }" />
                <div
                  *ngIf="submitted && f.brandName.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.brandName.errors.required">
                    Name is required
                  </div>
                </div>
              </div>

              <!--realityMineGroup -->
              <div class="form-group col-6">
                <label class="lead" for="brandLogo">Brand Logo</label>
                <input
                  type="text"
                  formControlName="brandLogo"
                  placeholder="http://bucket-name.s3-website-Region.amazonaws.com/images/logo.png"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.brandLogo.errors
                  }" />
                <div
                  *ngIf="submitted && f.brandLogo.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.brandLogo.errors.required">
                    Logo is required
                  </div>
                </div>
              </div>

              <!--xxxxxxx -->
            </div>
          </div>

          <!--Rewards -->
          <div class="container mt-5">
            <span class="lead">Rewards</span>
            <hr />
            <div class="row">
              <!--minPointsToRedeem:  -->
              <div class="form-group col-4">
                <label class="lead" for="minPointsToRedeem"
                  >Min Points To Redeem</label
                >
                <input
                  type="number"
                  formControlName="minPointsToRedeem"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.minPointsToRedeem.errors
                  }" />
                <div
                  *ngIf="submitted && f.minPointsToRedeem.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.minPointsToRedeem.errors.required">
                    Min Points To Redeem is required
                  </div>
                </div>
              </div>
              <!--minPointsToRedeemPaypal -->
              <div class="form-group col-4">
                <label class="lead" for="minPointsToRedeemPaypal"
                  >Min Points To Redeem Paypal</label
                >
                <input
                  type="number"
                  formControlName="minPointsToRedeemPaypal"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.minPointsToRedeemPaypal.errors
                  }" />
                <div
                  *ngIf="submitted && f.minPointsToRedeemPaypal.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.minPointsToRedeemPaypal.errors.required">
                    Min Points To Redeem Paypal is required
                  </div>
                </div>
              </div>
              <!--minPointsToRedeemVirtualVisa -->
              <div class="form-group col-4">
                <label class="lead" for="minPointsToRedeemVirtualVisa"
                  >Min Points To Redeem Virtual Visa</label
                >
                <input
                  type="number"
                  formControlName="minPointsToRedeemVirtualVisa"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.minPointsToRedeemVirtualVisa.errors
                  }" />
                <div
                  *ngIf="submitted && f.minPointsToRedeemVirtualVisa.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.minPointsToRedeemVirtualVisa.errors.required">
                    Min Points To Redeem Virtual Visa is required
                  </div>
                </div>
              </div>

              <!--firstRewardDelayHours -->
              <div class="form-group col-4">
                <label class="lead" for="fraudThreshold"
                  >First Reward Delay Hours</label
                >
                <input
                  type="number"
                  formControlName="firstRewardDelayHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.firstRewardDelayHours.errors
                  }" />
                <div
                  *ngIf="submitted && f.firstRewardDelayHours.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.firstRewardDelayHours.errors.required">
                    Hours are required
                  </div>
                </div>
              </div>
              <!--standardRewardDelayHours -->
              <div class="form-group col-4">
                <label class="lead" for="standardRewardDelayHours"
                  >Standard Reward Delay Hours</label
                >
                <input
                  type="number"
                  formControlName="standardRewardDelayHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.standardRewardDelayHours.errors
                  }" />
                <div
                  *ngIf="submitted && f.standardRewardDelayHours.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.standardRewardDelayHours.errors.required">
                    Hours are required
                  </div>
                </div>
              </div>

              <!--pointEqualTo -->
              <div class="form-group col-4">
                <label class="lead" for="pointEqualTo">Point Equal To</label>
                <input
                  type="number"
                  formControlName="pointEqualTo"
                  placeholder=".01"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.pointEqualTo.errors
                  }" />
                <div
                  *ngIf="submitted && f.pointEqualTo.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.pointEqualTo.errors.required">
                    PointEqualTo is required
                  </div>
                </div>
              </div>
              <!--pointEqualToDescription string -->
              <div class="form-group col-4">
                <label class="lead" for="pointEqualToDescription"
                  >Point Equal To Description</label
                >
                <input
                  type="text"
                  formControlName="pointEqualToDescription"
                  placeholder="1c USD"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.pointEqualToDescription.errors
                  }" />
                <div
                  *ngIf="submitted && f.pointEqualToDescription.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.pointEqualToDescription.errors.required">
                    Description is required
                  </div>
                </div>
              </div>
              <!--  virtualVisaTremendousId string -->
              <div class="form-group col-4">
                <label class="lead" for="virtualVisaTremendousId"
                  >Virtual Visa TremendousId</label
                >
                <input
                  type="text"
                  formControlName="virtualVisaTremendousId"
                  placeholder="ABC123"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.virtualVisaTremendousId.errors
                  }" />
                <div
                  *ngIf="submitted && f.virtualVisaTremendousId.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.virtualVisaTremendousId.errors.required">
                    Virtual Visa TremendousId is required
                  </div>
                </div>
              </div>
              <!--payPalTremendousID string -->
              <div class="form-group col-4">
                <label class="lead" for="payPalTremendousID"
                  >PayPal TremendousID</label
                >
                <input
                  type="text"
                  formControlName="payPalTremendousID"
                  placeholder="ABC123"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.payPalTremendousID.errors
                  }" />
                <div
                  *ngIf="submitted && f.payPalTremendousID.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.payPalTremendousID.errors.required">
                    PayPal TremendousID is required
                  </div>
                </div>
              </div>
              <!--currencyCode string -->
              <div class="form-group col-4">
                <label class="lead" for="currencyCode">Currency Code</label>
                <input
                  type="text"
                  formControlName="currencyCode"
                  placeholder="USD"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.currencyCode.errors
                  }" />
                <div
                  *ngIf="submitted && f.currencyCode.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.currencyCode.errors.required">
                    currency Code is required
                  </div>
                </div>
              </div>

              <!--currencySymbol  string -->
              <div class="form-group col-4">
                <label class="lead" for="currencySymbol"
                  >Currency Symbol Logo</label
                >
                <input
                  type="text"
                  formControlName="currencySymbol"
                  placeholder="$"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.currencySymbol.errors
                  }" />
                <div
                  *ngIf="submitted && f.currencySymbol.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.currencySymbol.errors.required">
                    Symbol is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Bonus -->
          <div class="container mt-5">
            <span class="lead">Bonus</span>
            <hr />
            <div class="row">
              <!--bonus -->
              <div class="form-group col-4">
                <label class="lead" for="bonus">Bonus</label>
                <input
                  type="number"
                  formControlName="bonus"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.bonus.errors
                  }" />
                <div
                  *ngIf="submitted && f.bonus.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.bonus.errors.required">Bonus is required</div>
                </div>
              </div>
              <!--bonusThreshold  -->
              <div class="form-group col-4">
                <label class="lead" for="bonusThreshold">Bonus Threshold</label>
                <input
                  type="number"
                  formControlName="bonusThreshold"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.bonusThreshold.errors
                  }" />
                <div
                  *ngIf="submitted && f.bonusThreshold.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.bonusThreshold.errors.required">
                    Threshold is required
                  </div>
                </div>
              </div>
              <!--allDemosAnsweredReward -->
              <div class="form-group col-4">
                <label class="lead" for="allDemosAnsweredReward"
                  >All Demos Answered Bonus</label
                >
                <input
                  type="number"
                  formControlName="allDemosAnsweredReward"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.allDemosAnsweredReward.errors
                  }" />
                <div
                  *ngIf="submitted && f.allDemosAnsweredReward.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.allDemosAnsweredReward.errors.required">
                    Bonus is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Referral -->
          <div class="container mt-5">
            <span class="lead">Referral</span>
            <hr />
            <div class="row">
              <div class="form-group col-2">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="isAllowReferrals"
                      type="checkbox" />
                    <label class="form-check-label">Allow Referral </label>
                  </div>
                </div>
              </div>

              <!--bonus -->
              <div class="form-group col-4">
                <label class="lead" for="referralAmount">Referral Bonus</label>
                <input
                  type="number"
                  formControlName="referralAmount"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.referralAmount.errors
                  }" />
                <div
                  *ngIf="submitted && f.referralAmount.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.referralAmount.errors.required">
                    Bonus is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Activity -->
          <div class="container mt-5">
            <span class="lead">Activity</span>
            <hr />
            <div class="row">
              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="realityMineAccessibility"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Reality Mine Accessibility Tracking</label
                    >
                  </div>
                </div>
              </div>

              <!--pointsPerDayForActivityTracking -->
              <div class="form-group col-4">
                <label class="lead" for="bonus"
                  >Points Per Day For Activity Tracking</label
                >
                <input
                  type="number"
                  formControlName="pointsPerDayForActivityTracking"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.pointsPerDayForActivityTracking.errors
                  }" />
                <div
                  *ngIf="submitted && f.pointsPerDayForActivityTracking.errors"
                  class="invalid-feedback">
                  <div
                    *ngIf="f.pointsPerDayForActivityTracking.errors.required">
                    Points are required
                  </div>
                </div>
              </div>
              <!--activityTrackingLastConnectionTimeoutHours  -->
              <div class="form-group col-4">
                <label
                  class="lead"
                  for="activityTrackingLastConnectionTimeoutHours"
                  >Activity Last Connection Timeout Hours</label
                >
                <input
                  type="number"
                  formControlName="activityTrackingLastConnectionTimeoutHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.activityTrackingLastConnectionTimeoutHours.errors
                  }" />
                <div
                  *ngIf="
                    submitted &&
                    f.activityTrackingLastConnectionTimeoutHours.errors
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="
                      f.activityTrackingLastConnectionTimeoutHours.errors
                        .required
                    ">
                    Hours are required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Location -->
          <div class="container mt-5">
            <span class="lead">Location</span>
            <hr />
            <div class="row">
              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="foursquareLocation"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Foursquare Location Tracking
                    </label>
                  </div>
                </div>
              </div>

              <!--pointsPerDayForLocationTracking -->
              <div class="form-group col-4">
                <label class="lead" for="pointsPerDayForLocationTracking"
                  >Points Per Day For Location Tracking</label
                >
                <input
                  type="number"
                  formControlName="pointsPerDayForLocationTracking"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.pointsPerDayForLocationTracking.errors
                  }" />
                <div
                  *ngIf="submitted && f.pointsPerDayForLocationTracking.errors"
                  class="invalid-feedback">
                  <div
                    *ngIf="f.pointsPerDayForLocationTracking.errors.required">
                    Points are required
                  </div>
                </div>
              </div>
              <!--locationTrackingLastConnectionTimeoutHours  -->
              <div class="form-group col-4">
                <label
                  class="lead"
                  for="locationTrackingLastConnectionTimeoutHours"
                  >Location Last Connection Timeout Hours</label
                >
                <input
                  type="number"
                  formControlName="locationTrackingLastConnectionTimeoutHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.locationTrackingLastConnectionTimeoutHours.errors
                  }" />
                <div
                  *ngIf="
                    submitted &&
                    f.locationTrackingLastConnectionTimeoutHours.errors
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="
                      f.locationTrackingLastConnectionTimeoutHours.errors
                        .required
                    ">
                    Hours are required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Microphone -->
          <div class="container mt-5">
            <span class="lead">Microphone</span>
            <hr />
            <div class="row">
              <!--kantarACR -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="kantarACR"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Kantar Microphone Tracking
                    </label>
                  </div>
                </div>
              </div>

              <!--aCRPointsPerDay -->
              <div class="form-group col-4">
                <label class="lead" for="pointsPerDayForMicrophoneTracking"
                  >Points Per Day For Microphone Tracking</label
                >
                <input
                  type="number"
                  formControlName="pointsPerDayForMicrophoneTracking"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.pointsPerDayForMicrophoneTracking.errors
                  }" />
                <div
                  *ngIf="
                    submitted && f.pointsPerDayForMicrophoneTracking.errors
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="f.pointsPerDayForMicrophoneTracking.errors.required">
                    Points are required
                  </div>
                </div>
              </div>
              <!--locationTrackingLastConnectionTimeoutHours  -->
              <div class="form-group col-4">
                <label
                  class="lead"
                  for="microphoneTrackingLastConnectionTimeoutHours"
                  >Microphone Last Connection Timeout Hours</label
                >
                <input
                  type="number"
                  formControlName="microphoneTrackingLastConnectionTimeoutHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      f.microphoneTrackingLastConnectionTimeoutHours.errors
                  }" />
                <div
                  *ngIf="
                    submitted &&
                    f.microphoneTrackingLastConnectionTimeoutHours.errors
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="
                      f.microphoneTrackingLastConnectionTimeoutHours.errors
                        .required
                    ">
                    Hours are required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Fraud -->
          <div class="container mt-5">
            <span class="lead">Fraud</span>
            <hr />
            <div class="row">
              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudOnBoardingCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Onboarding Check</label
                    >
                  </div>
                </div>
              </div>

              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudLocationCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Location Check
                    </label>
                  </div>
                </div>
              </div>

              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudAppsCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Apps Check
                    </label>
                  </div>
                </div>
              </div>

              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudHomeCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Home Check
                    </label>
                  </div>
                </div>
              </div>

              <!--realityMineAccessibility -->
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudSurveyCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Survey Check
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group col-4">
                <div class="col mt-4">
                  <div class="form-check form-check-inline form-switch">
                    <input
                      class="form-check-input"
                      formControlName="fraudBatteryCheckEnabled"
                      type="checkbox" />
                    <label class="form-check-label"
                      >Enable Fraud Battery Check
                    </label>
                  </div>
                </div>
              </div>

              <!--fraudCoolingOffDays:  -->
              <div class="form-group col-6 mt-4">
                <label class="lead" for="fraudCoolingOffDays"
                  >Fraud Cooling Off Period Days</label
                >
                <input
                  type="number"
                  formControlName="fraudCoolingOffDays"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.fraudCoolingOffDays.errors
                  }" />
                <div
                  *ngIf="submitted && f.fraudCoolingOffDays.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.fraudCoolingOffDays.errors.required">
                    Days are required
                  </div>
                </div>
              </div>

              <!--bonus -->
              <div class="form-group col-6 mt-4">
                <label class="lead" for="fraudTimeframeHours"
                  >Fraud Timeframe Hours</label
                >
                <input
                  type="number"
                  formControlName="fraudTimeframeHours"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && f.fraudTimeframeHours.errors
                  }" />
                <div
                  *ngIf="submitted && f.fraudTimeframeHours.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.fraudTimeframeHours.errors.required">
                    Hours required
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Palette Section -->
      <div
        class="card mt-2"
        *ngIf="authService.user?.userDetails?.dash != true">
        <div class="card-header">ValueMe Color Palette</div>
        <!-- start of body -->
        <div class="row p-2">
          <div class="container">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true">
                  Primary
                  <span
                    class="danger"
                    *ngIf="
                      submitted &&
                      (f.palettePrimaryDefault.errors ||
                        f.palettePrimaryMedium.errors ||
                        f.palettePrimaryLight.errors ||
                        f.palettePrimaryDark.errors)
                    "
                    >⚠</span
                  >
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false">
                  Neutral
                  <span
                    class="danger"
                    *ngIf="
                      submitted &&
                      (f.paletteNeutralWhite.errors ||
                        f.paletteNeutralGrey100.errors ||
                        f.paletteNeutralGrey100.errors ||
                        f.paletteNeutralGrey200.errors ||
                        f.paletteNeutralGrey300.errors ||
                        f.paletteNeutralGrey400.errors ||
                        f.paletteNeutralGrey500.errors ||
                        f.paletteNeutralGrey600.errors ||
                        f.paletteNeutralGrey700.errors ||
                        f.paletteNeutralGrey800.errors ||
                        f.paletteNeutralGrey900.errors ||
                        f.paletteNeutralBlack.errors)
                    "
                    >⚠</span
                  >
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false">
                  State
                  <span
                    *ngIf="
                      submitted &&
                      (f.paletteNeutralWhite.errors ||
                        f.paletteStateSuccess.errors ||
                        f.paletteStateError.errors)
                    "
                    >⚠</span
                  >
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="other-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#other_tab"
                  type="button"
                  role="tab"
                  aria-controls="other_tab"
                  aria-selected="other_tab">
                  Other
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab">
                <div class="row">
                  <div class="form-group col-3">
                    <label for="palettePrimaryDefault">Default</label>
                    <input
                      type="string"
                      formControlName="palettePrimaryDefault"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.palettePrimaryDefault.errors
                      }" />
                    <div
                      *ngIf="submitted && f.palettePrimaryDefault.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.palettePrimaryDefault.errors.required">
                        Default is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="palettePrimaryMedium">Medium</label>
                    <input
                      type="string"
                      formControlName="palettePrimaryMedium"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.palettePrimaryMedium.errors
                      }" />
                    <div
                      *ngIf="submitted && f.palettePrimaryMedium.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.palettePrimaryMedium.errors.required">
                        Medium is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="palettePrimaryLight">Light</label>
                    <input
                      type="string"
                      formControlName="palettePrimaryLight"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.palettePrimaryLight.errors
                      }" />
                    <div
                      *ngIf="submitted && f.palettePrimaryLight.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.palettePrimaryLight.errors.required">
                        Light is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="palettePrimaryDark">Dark</label>
                    <input
                      type="string"
                      formControlName="palettePrimaryDark"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.palettePrimaryDark.errors
                      }" />
                    <div
                      *ngIf="submitted && f.palettePrimaryDark.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.palettePrimaryDark.errors.required">
                        Dark is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab">
                <div class="row">
                  <div class="form-group col-3">
                    <label for="paletteNeutralWhite">White</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralWhite"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.paletteNeutralWhite.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralWhite.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralWhite.errors.required">
                        White is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey100">Grey100</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey100"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey100.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey100.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey100.errors.required">
                        Grey100 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey200">Grey200</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey200"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey200.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey200.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey200.errors.required">
                        Grey200 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey300">Grey300</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey300"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey300.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey300.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey300.errors.required">
                        Grey300 is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey400">Grey400</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey400"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey400.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey400.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey400.errors.required">
                        Grey400 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey500">Grey500</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey500"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey500.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey500.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey500.errors.required">
                        Grey500 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey600">Grey600</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey600"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey600.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey600.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey600.errors.required">
                        Grey600 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey700">Grey700</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey700"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey700.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey700.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey700.errors.required">
                        Grey700 is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey800">Grey800</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey800"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey800.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey800.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey800.errors.required">
                        Grey800 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralGrey900">Grey900</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralGrey900"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && f.paletteNeutralGrey900.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralGrey900.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralGrey900.errors.required">
                        Grey900 is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteNeutralBlack">Black</label>
                    <input
                      type="string"
                      formControlName="paletteNeutralBlack"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.paletteNeutralBlack.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteNeutralBlack.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteNeutralBlack.errors.required">
                        Black is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab">
                <div class="row">
                  <div class="form-group col-3">
                    <label for="paletteStateSuccess">Success</label>
                    <input
                      type="string"
                      formControlName="paletteStateSuccess"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.paletteStateSuccess.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteStateSuccess.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteStateSuccess.errors.required">
                        Success is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="paletteStateError">Error</label>
                    <input
                      type="string"
                      formControlName="paletteStateError"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.paletteStateError.errors
                      }" />
                    <div
                      *ngIf="submitted && f.paletteStateError.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.paletteStateError.errors.required">
                        Error is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="other_tab"
                role="tabpanel"
                aria-labelledby="other-tab">
                <!-- Other text goes here -->
              </div>
            </div>
          </div>
        </div>
        <!-- end of body-->
      </div>
    </div>
    <div class="card-footer">
      <div class="form-group float-end">
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <a routerLink="/instances" class="btn btn-link">Cancel</a>
      </div>
    </div>
  </div>
</form>
