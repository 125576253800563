import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinalCompletesModalComponent } from "../_components/final-completes-modal/final-completes-modal.component";


@Injectable()
export class FinalCompletesModalService {

  constructor(private modalService: NgbModal) { }

  public open(projectId: string) {
    const modalRef = this.modalService.open(FinalCompletesModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    modalRef.componentInstance.projectId = projectId;
    return modalRef.result;
  }

}