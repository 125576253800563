import { EditInboxMessageComponent } from './_sections/inbox-messages/edit-inbox-message/edit-inbox-message.component';
import { TargetVidsComponent } from './_components/target-vids/target-vids.component';
import { AddEditCampaignComponent } from './_sections/campaigns/campaign/add-edit-campaign/add-edit-campaign.component';
import { CampaignComponent } from './_sections/campaigns/campaign/campaign.component';
import { UniqueIdsComponent } from './_components/unique-ids/unique-ids.component';
import { DropdownComponent } from './_sections/dropdown/dropdown.component';
import { TargetsComponent } from './_sections/project/targets/targets.component';
import { QuotasComponent } from './_sections/project/quotas/quotas.component';
import { DemographicsComponent } from './_sections/project/demographics/demographics.component';
import { ProjectPerformanceComponent } from './_sections/project/project-performance/project-performance.component';
import { EditProjectComponent } from './_sections/project/edit-project/edit-project.component';
import { ViewProjectComponent } from './_sections/project/view-project/view-project.component';
import { AddProjectComponent } from './_sections/project/add-project/add-project.component';
import { ProjectComponent } from './_sections/project/project.component';
import { AddUpdateVendorComponent } from './_sections/vendors/add-update-vendor/add-update-vendor.component';
import { AddUpdateClientComponent } from './_sections/clients/add-update-client/add-update-client.component';
import { VendorsComponent } from './_sections/vendors/vendors.component';
import { ClientsComponent } from './_sections/clients/clients.component';
import { RegisterUpdateUserComponent } from './_sections/users/register-update-user/register-update-user.component';
import { AddEditInstanceComponent } from './_sections/instance/add-edit-instance/add-edit-instance.component';
import { InstanceComponent } from './_sections/instance/instance.component';
import { ExamplesComponent } from './_sections/examples/examples.component';
import { UsersComponent } from './_sections/users/users.component';
import { ReleaseNotesComponent } from './_sections/release-notes/release-notes.component';
import { MinuteSecondsPipe } from './_pipes/minuteSeconds.pipe';
import { CheckComponent } from './_components/check/check.component';
import { UploadComponent } from './_components/upload/upload.component';
import { MailComponent } from './_components/mail/mail.component';
import { InitialsComponent } from './_components/initials/initials.component';
import { ListComponent } from './_components/list/list.component';
import { LinkComponent } from './_components/link/link.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClipboardModule } from 'ngx-clipboard';

import { ReferralComponent } from './_sections/referral/referral.component';
import { AddEditReferralComponent } from './_sections/referral/add-edit-referral/add-edit-referral.component';
import { PointsHistoryViewComponent } from './_sections/review-user/points-history-view/points-history-view.component';
import { RewardHistoryViewComponent } from './_sections/review-user/reward-history-view/reward-history-view.component';
import { PendingRewardsComponent } from './_sections/pending-rewards/pending-rewards.component';
import { ReviewuserComponent } from './_sections/review-user/review-user.component';
import { UserViewComponent } from './_sections/nubble/user-view/user-view.component';
import { UserDetailsComponent } from './_sections/review-user/user-details/user-details.component';
import { RmComplianceReportComponent } from './_sections/review-user/rm-compliance-report/rm-compliance-report.component';
import { QuicksightDashboardComponent } from './_sections/review-user/quicksight-dashboard/quicksight-dashboard.component';
import { WebsiteVisitsComponent } from './_sections/review-user/website-visits/website-visits.component';
import { SafePipe } from './_pipes/safe.pipe';
import { QuicksightGeolocationMapComponent } from './_sections/review-user/quicksight-geolocation-map/quicksight-geolocation-map.component';
import { EngagementComponent } from './_sections/engagement/engagement.component';
import { LoadingIconComponent } from './_components/loading-icon/loading-icon.component';
import { MarkersComponent } from './_sections/markers/markers.component';
import { AddUpdateMarkerComponent } from './_sections/markers/add-update-marker/add-update-marker.component';
import { SurveyHistoryComponent } from './_sections/review-user/survey-history/survey-history.component';
import { QuicksightAppusageChartComponent } from './_sections/review-user/quicksight-appusage-chart/quicksight-appusage-chart.component';
import { FraudFlagsListUsersComponent } from './_sections/fraud-flags-list-users/fraud-flags-list-users.component';
import { CardComponent } from './_components/card/card.component';
import { PrettyPrintPipe } from './_pipes/prettyPrintPipe.pipe';
import { UserFraudFlagsComponent } from './_sections/review-user/user-fraud-flags/user-fraud-flags.component';
import { UserAppHistoryListComponent } from './_sections/review-user/user-app-history-list/user-app-history-list.component';
import { UserLocationsListComponent } from './_sections/review-user/user-locations-list/user-locations-list.component';
import { TestDropdownsComponent } from './_sections/markers/test-dropdowns/test-dropdowns.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NotesWyswygComponent } from './_sections/review-user/notes-wyswyg/notes-wyswyg.component';
import { UserFraudBatteryListComponent } from './_sections/review-user/user-fraud-battery-list/user-fraud-battery-list.component';
import { CreateInboxMessageComponent } from './_sections/inbox-messages/create-inbox-message/create-inbox-message.component';
import { ListInboxMessagesComponent } from './_sections/inbox-messages/list-inbox-messages/list-inbox-messages.component';
import { MarkersListComponent } from './_sections/markers/markers-list/markers-list.component';
import { CookieService } from 'ngx-cookie-service';

//PRIME NG MODULE IMPORTS
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';

import { ConfirmationService, MessageService } from 'primeng/api';
import { SearchListUsersComponent } from './_sections/search-list-users/search-list-users.component';
import { PercentageTextColorPipe } from './_pipes/percentageTextColor.pipe';
import { PercentageBadgeColorPipe } from './_pipes/percentageBadgeColor.pipe';
import { ClientUsersComponent } from './_sections/client-users/client-users.component';
import { ConfirmModalComponent } from './_components/confirm-modal/confirm-modal.component';
import { ConfirmModalService } from './_services/confirm-modal.service';
import { VendorKeysComponent } from './_sections/vendors/vendor-keys/vendor-keys.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TagModule } from 'primeng/tag';
import { NotesComponent } from './_sections/project/notes/notes.component';
import { RippleModule } from 'primeng/ripple';
import { TextFieldModule } from '@angular/cdk/text-field';
import { RouterModule } from '@angular/router';
import { AutosizeModule } from 'ngx-autosize';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FinalCompletesModalComponent } from './_components/final-completes-modal/final-completes-modal.component';
import { FinalCompletesModalService } from './_services/final-completes-modal.service';

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    DropdownComponent,
    ExamplesComponent,
    LinkComponent,
    ListComponent,
    InitialsComponent,
    CheckComponent,
    MailComponent,
    UploadComponent,
    InstanceComponent,
    AddEditInstanceComponent,
    RegisterUpdateUserComponent,
    ClientsComponent,
    VendorsComponent,
    AddUpdateClientComponent,
    AddUpdateVendorComponent,
    ProjectComponent,
    AddProjectComponent,
    ViewProjectComponent,
    EditProjectComponent,
    ProjectPerformanceComponent,
    MinuteSecondsPipe,
    DemographicsComponent,
    QuotasComponent,
    TargetsComponent,
    UniqueIdsComponent,
    TargetVidsComponent,
    ReferralComponent,
    AddEditReferralComponent,
    PointsHistoryViewComponent,
    RewardHistoryViewComponent,
    AddEditCampaignComponent,
    CampaignComponent,
    PendingRewardsComponent,
    ReviewuserComponent,
    UserViewComponent,
    UserDetailsComponent,
    RmComplianceReportComponent,
    WebsiteVisitsComponent,
    QuicksightDashboardComponent,
    SafePipe,
    QuicksightGeolocationMapComponent,
    EngagementComponent,
    LoadingIconComponent,
    MarkersComponent,
    AddUpdateMarkerComponent,
    SurveyHistoryComponent,
    QuicksightAppusageChartComponent,
    FraudFlagsListUsersComponent,
    CardComponent,
    PrettyPrintPipe,
    UserFraudFlagsComponent,
    UserAppHistoryListComponent,
    UserLocationsListComponent,
    TestDropdownsComponent,
    NotesWyswygComponent,
    UserFraudBatteryListComponent,
    CreateInboxMessageComponent,
    ListInboxMessagesComponent,
    EditInboxMessageComponent,
    MarkersListComponent,
    SearchListUsersComponent,
    ClientUsersComponent,
    PercentageTextColorPipe,
    PercentageBadgeColorPipe,
    ConfirmModalComponent,
    VendorKeysComponent,
    NotesComponent,
    ReleaseNotesComponent,
    FinalCompletesModalComponent,
  ],
  imports: [
    CommonModule,
    FroalaViewModule.forRoot(),
    FroalaEditorModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    SelectDropDownModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AmplifyAuthenticatorModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    DropdownModule,
    ToolbarModule,
    RadioButtonModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    OverlayPanelModule,
    BadgeModule,
    TooltipModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    NgbModule,
    ClipboardModule,
    InputSwitchModule,
    TagModule,
    RippleModule,
    TextFieldModule,
    AutosizeModule,
    SelectButtonModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    ConfirmationService,
    MessageService,
    ConfirmModalService,
    FinalCompletesModalService,
    CookieService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
