import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ProjectService } from '../../_services/project.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DropDownService } from '../../_services/drop-down.service';
import { DropDown } from '../../_models/drop-down';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  links: any[] = [];

  actions: any[] = [];

  users: any[] = [];
  items: any[] = [];
  itemsAll: any[] = [];
  itemsInvoiced: any[] = [];
  selectedStatus = 'All';
  selectedUser = '';
  searchText = '';
  loading = false;

  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: IDropdownSettings = {};

  constructor(
    private authService: AuthService,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private router: Router,
    private dd: DropDownService,
  ) {
    if (
      this.authService.user?.userDetails?.instanceId ===
      '926bcd4a-ce39-4409-804b-f54e08bf8b40'
    ) {
      this.selectedUser =
        this.authService.user?.userDetails?.firstName +
        ' ' +
        this.authService.user?.userDetails?.lastName.charAt(0);
    }
    this.getUsers();
    this.getActiveProjects();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false,
    };
  }

  getSeverity(ir: number) {
    if (ir > 0.9) {
      return 'success';
    } else if (ir <= 0.9 && ir >= 0.7) {
      return 'warning';
    } else if (ir > 0) {
      return 'danger';
    } else {
      return '';
    }
  }

  getIrClass(ir: number, estimateIR: number) {
    if (estimateIR <= 0) {
      return '';
    }

    // Convert estimateIR from whole number percent to decimal then divide ir by that
    const irPercent = ir / (estimateIR / 100);

    if (irPercent > 0.9) {
      return 'badge bg-success';
    } else if (irPercent <= 0.9 && irPercent >= 0.7) {
      return 'badge bg-warning';
    } else if (irPercent > 0) {
      return 'badge bg-danger';
    } else {
      return '';
    }
  }

  getDorClass(dor: number) {
    if (dor >= 0.5) {
      return 'badge bg-danger';
    } else if (dor >= 0.25 && dor < 0.5) {
      return 'badge bg-warning';
    } else {
      return '';
    }
  }

  handleAction(action: any) {
    if (action.type === 'edit') {
      this.router.navigate(['/projects/edit/' + action.row.id]);
    } else if (action.type === 'add') {
      this.router.navigate(['/projects/add']);
    } else if (action.type === 'view') {
      this.router.navigate(['/projects/view/' + action.row.id]);
    }
  }

  getActiveProjects() {
    this.loading = true;
    this.projectService.listActiveProjects().subscribe(
      (data) => {
        this.items = data;
        this.itemsAll = data;
      },
      (error) => {
        this.loading = false;
        this.toastr.error('Failed to fetch projects');
      },
      () => {
        //complete
        this.search();
        this.loading = false;
      },
    );
  }

  getInvoicedProjects() {
    this.loading = true;
    this.projectService.listInvoicedProjects().subscribe(
      (data) => {
        this.items = data;
        this.itemsAll = data;
        this.itemsInvoiced = data;
      },
      (error) => {
        this.loading = false;
        this.toastr.error('Failed to fetch projects');
      },
      () => {
        //complete
        this.search();
        this.loading = false;
      },
    );
  }

  filterOnStatus(event: any) {
    this.selectedStatus = event.value.replace('Full Launch', 'Live');
    event.value === 'Invoiced'
      ? this.getInvoicedProjects()
      : this.getActiveProjects();
    this.search();
  }

  filterOnUser(event: any) {
    this.selectedUser = event.value;
    this.search();
  }

  inputChange(event: any) {
    this.searchText = event.value;
    this.search();
  }

  search() {
    this.items = this.itemsAll;

    this.abbreviate();

    if (
      this.selectedStatus === 'All' &&
      this.selectedUser === '' &&
      this.searchText === ''
    ) {
      this.items = this.itemsAll;
      this.abbreviate();
      return;
    }

    if (this.selectedStatus.toLowerCase() === 'invoiced') {
      this.items = this.itemsInvoiced;
    } else if (this.selectedStatus === 'All') {
      this.items = this.itemsAll;
    } else {
      this.items = this.itemsAll.filter((s) =>
        s.projectStatus.includes(this.selectedStatus),
      );
    }

    if (this.selectedUser !== '') {
      this.abbreviate();
      this.items = this.items.filter(
        (s) =>
          s.salesRep === this.selectedUser ||
          s.projectManager === this.selectedUser,
      );
    }

    if (this.searchText !== '') {
      this.items = this.items.filter(
        (s) =>
          (s.dashCode || s.projectCode)
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          s.deadline?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          s.salesRep?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          s.projectManager
            ?.toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          s.projectName.toLowerCase().includes(this.searchText.toLowerCase()) ||
          s.client.toLowerCase().includes(this.searchText.toLowerCase()),
      ); // || s.projectManager.includes(searchText));
    }
  }

  abbreviate() {
    this.items.forEach((item) => {
      if (item.projectManager != 'Biz Dev') {
        if (item.projectManager?.includes(' ')) {
          item.projectManager =
            item.projectManager.split(' ', 2)[0] +
            ' ' +
            item.projectManager.split(' ', 2)[1].charAt(0);
        }
      }

      if (item.salesRep != 'Biz Dev') {
        if (item.salesRep?.includes(' ')) {
          item.salesRep =
            item.salesRep.split(' ', 2)[0] +
            ' ' +
            item.salesRep.split(' ', 2)[1].charAt(0);
        }
      }

      //Replace full launch description
      item.projectStatus = item.projectStatus.replace('Full Launch', 'Live');
    });
  }

  abbreviateUsers(rows: DropDown[]): DropDown[] {
    rows.forEach((row) => {
      row.option =
        row.option.split(' ', 2)[0] + ' ' + row.option.split(' ', 2)[1].charAt(0);
    });
    return rows;
  }

  exportProjectList() {
    this.projectService
      .downloadProjectsCSV(this.selectedStatus)
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'ProjectList.csv';
        hiddenElement.click();
      });
  }

  getUsers() {
    this.dd.users().subscribe(
      (data) => {
        this.users = this.abbreviateUsers(data);
      },
      (error) => {
        console.log(error);
        this.toastr.error('Unable to fetch users');
      },
      () => {
        //complete
        this.search();
      },
    );
  }
}
