import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParticipantService } from '../../_services/participant.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ParticipantValidationSummary } from '../../_models/participants-validation-result';
import { CustomToastrService } from '../../_services/custom-toastr.service';

@Component({
  selector: 'app-final-completes-modal',
  templateUrl: './final-completes-modal.component.html',
  styleUrls: ['./final-completes-modal.component.css'],
})
export class FinalCompletesModalComponent implements OnInit {
  form: FormGroup;
  @Input() projectId: string;
  loading: boolean = false;
  results: ParticipantValidationSummary[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private toastr: ToastrService,
    private customToastr: CustomToastrService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      spids: ['', Validators.required],
    });
  }

  public uploadSpids(fileInput: any) {
    let file = fileInput.target.files[0];
    let reader: FileReader = new FileReader();

    reader.readAsText(file);

    reader.onload = (e) => {
      let csv: string = reader.result as string;
      var spidValues = csv.replace(/[\n\r]+/g, '-').split('-');
      spidValues.shift(); // remove header
      this.form.patchValue({
        spids: spidValues,
      });
    };
  }

  public async save() {
    const spidsPayload: string[] = this.form.value;

    try {
      this.loading = true;
      if (this.results.length > 0) {
        await lastValueFrom(
          this.participantService.updateSpids(this.projectId, spidsPayload),
        );

        this.customToastr.showSuccess('Successfully updated SPIDs.');
        // reload the page to retrieve fresh data.
        location.reload();
      } else {
        this.results = await lastValueFrom(
          this.participantService.validateSpids(this.projectId, spidsPayload),
        );
      }
    } catch (error) {
      this.toastr.error('Failed to validate participants');
    } finally {
      this.loading = false;
    }
  }

  public decline() {
    this.activeModal.close();
  }

  public accept() {
    this.activeModal.close();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  get f() {
    return this.form.controls;
  }
}
